body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'source-sans'
    sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "nadia";   
  src: local("nadia_serif"),
    url("../src/fonts/nadia_serif.ttf") format("truetype");
}

@font-face {
  font-family: "quicksand-book";   /*Can be any text*/
  src: local("Quicksand_Boook"),
    url("../src/fonts/Quicksand_Book.otf") format("opentype");
    
    

   
}


@font-face {
  font-family: "source-sans";
  src:
  url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap');

}


